$rightColumnWidth: 232px;

#changePassword {
    margin: 0;
    padding: 0;
    line-height: 1.5em;
    background-color: #edf0f3;
    font-family: 'Segoe UI', sans-serif;
    font-size: 13px;


    .k-window-content {
        padding: 0px !important;
    }

    .wrapper-default-height {
        height: 100%;
    }

    #wrapper {
        min-height: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
        background: none;
    }

    .forced-reset {
        #wrapper {
            width: 700px;
            height: 265px !important;
            min-height: 250px !important;
            border: 1px solid #000;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);

            #rightColumn, #contentColumn {
                min-height: 250px !important;
            }
        }

        #resetMessage {
            display: block;
        }
    }

    #contentWrapper {
        float: left;
        width: 100%;
        height: 100%;
    }

    #errorBox {
        text-align: center;
        margin: 5px 0px 3px 0px;
        color: red;
    }

    #contentColumn {
        margin-right: $rightColumnWidth;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#e0e4e7', endColorStr='#cdd3dd');
        -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#e0e4e7', endColorstr='#cdd3dd')";
        background: -webkit-gradient(linear, left top, left bottom, from(#e0e4e7), to(#cdd3dd));
        background: -moz-linear-gradient(top, #e0e4e7, #cdd3dd);
        background: linear-gradient(to bottom,#e0e4e7 0,#cdd3dd 100%);
        height: 100%;

        #contentColumnContent {
            width: 414px;
            margin: 0 auto;
            padding-top: 20px;

            label {
                color: #37629f;
                text-shadow: 0 1px 1px #fff;
                font-size: 16px;
            }

            td {
                padding: 5px;
                text-align: right;
            }

            input[type=password] {
                padding: 6px;
                width: 190px;
                border: 1px solid #abadb3;

                &:focus {
                    border: 1px solid #6dbdd1;
                }

                &.error {
                    border: 1px solid red;
                }
            }

            .save-btn {
                display: block;
                margin: 20px auto 0 auto;
            }
        }
    }

    #rightColumn {
        float: left;
        width: $rightColumnWidth;
        height: 100%;
        margin-left: $rightColumnWidth * -1;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#a4a9af', endColorStr='#9da2a8');
        -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#a4a9af', endColorstr='#9da2a8')";
        background: -webkit-gradient(linear, left top, left bottom, from(#a4a9af), to(#9da2a8));
        background: -moz-linear-gradient(top, #a4a9af, #9da2a8);
        background: linear-gradient(to bottom,#a4a9af 0,#9da2a8 100%);

        #rightColumnContent {
            text-align: center;

            .alert-info {
                margin: 14px auto 20px auto;
            }

            #passwordPolicy {
                margin: 0;
                padding: 0 20px 0 20px;
                color: #fff;
                font-weight: bold;
                text-shadow: 0 2px 3px #333;
            }
        }
    }

    #resetMessage {
        display: none;
        margin: 0 0 8px 0;
        text-align: center;
    }
}

