.smi-grid {

    > div {
        height: 100%;
    }

    .grid-toolbar {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        min-height: 36px;

        .k-icon {
            margin-right: 4px;
        }

        .inline-success {
            display: none;
            border-radius: 4px;
            font-weight: bold;
            font-size: 16px;
            margin-left: 6px;
            position: relative;
            top: 3px;
            padding: 2px 4px 2px 30px;
            border: 2px solid #61ac11;
            background: url('../../images/alerts_save_success.png') no-repeat 2px 0;
            animation: inlineSuccessFadeInOut;
            animation-duration: 4s;
        }

        .inline-error {
            display: none;
            border-radius: 4px;
            font-weight: bold;
            font-size: 16px;
            margin-left: 6px;
            position: relative;
            top: 3px;
            padding: 2px 4px 2px 30px;
            border: 2px solid #ca1911;
            background: url('../../images/alerts_save_error.png') no-repeat 2px 0;
            animation: inlineSuccessFadeInOut;
            animation-duration: 4s;
        }
    }

    .k-grid {
        height: calc(100% - 40px);

        @media only screen and (max-width: 600px) {
            table {
                width:1000px !important;
            }
        }

        .k-grid-norecords {
            pointer-events: none;
        }

        .k-grid-content > div:first-child {
            /* The grid content seems to overflow the page and cause a scrollbar if there are a lot of rows, unless this rule is included. I'm not sure why it works. */
            height: 0;
        }

        .blocked-cell {
            color: #999999;
        }

        th.k-header {
            background: linear-gradient(to bottom, #fcfcfa 0, #d4d4d4 100%);
            font-weight: bold;
            color: #000;
            min-width: 50px;
        }

        .k-group-indicator {
            color: #747474;
        }

        ::selection {
            background-color: #0078d7;
        }

        .k-colorpicker {
            width: 58px;
            margin: 0 auto;
        }

        .k-grid-edit-row > td > .k-textbox, .k-grid-edit-row > td > .text-box {
            margin-left: 0;
        }

        .k-grid-edit-row {
            .k-widget.k-dropdown {
                width: 100%;
            }
        }

        tr.k-grid-edit-row, tr.k-state-selected {
            background-color: #bee47b;

            > td {
                background-color: #bee47b;
            }
        }

        .color-display {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
        /* Hide any extra cells that are rendered in the grouping headers */
        .k-grouping-row > td:not([colspan]) {
            display: none;
        }

        .k-grouping-header {
            background-image: none, linear-gradient(to bottom, #8eafe4 8%, #6e94cc 58%, #3e6eb0 62%);
            color: #fff;

            .k-group-indicator {
                background: linear-gradient(to bottom, #fcfcfa 0, #d4d4d4 100%);

                .k-link {
                    text-transform: capitalize;
                }
            }
        }

        .k-button.disabled {
            pointer-events: none;
            opacity: 0.5;
        }

        td {
            min-width: 50px;

            &.dirty {
                box-shadow: 0 0 3px 1px blue inset;
            }

            &.error {
                box-shadow: 0 0 3px 1px red inset;
            }

            .k-textbox {
                border: 2px solid #6387b7;
                border-radius: 2px;
                padding: 2px 2px 2px 3px;
                height: 27px;
                margin-top: 0;
                margin-bottom: 0;
                line-height: 1.6em;
                outline: 0 none;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                width: 100%;
            }

            .inherited {
                color: #999;
            }
        }
    }
}

.csv-update-dialog {
    padding: 16px;

    p {
        margin: 0;
        display:flex;
        label{
            float:left;
        }
    }

    button {
        margin-top: 10px;
    }
}

.k-toolbar, .k-grid-toolbar, .silverlight-button-zone {
    .k-button {
        color: #000;
        font-weight: bold;
        border: 1px solid #bebfc3;
        -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#e9edf0', endColorstr='#ced1d6')";
        filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#e9edf0', endColorStr='#ced1d6');
        background: -webkit-gradient(linear, left top, left bottom, from(#e9edf0), to(#ced1d6));
        background: -moz-linear-gradient(top, #e9edf0, #ced1d6);
        background: linear-gradient(to bottom,#e9edf0 0,#ced1d6 100%);
        margin: 0 0.48em;
        -webkit-transition: background 0.2s ease;
        -moz-transition: background 0.2s ease;
        -o-transition: background 0.2s ease;
        transition: background 0.2s ease;

        .k-icon {
            background: url('../../images/grid-button-sprites.png') no-repeat;
            width: 28px;
            height: 26px;

            &.k-add, &.k-i-add, &.k-i-plus {
                background-position: -28px 0;
            }

            &.k-i-custom {
                background-position: -84px 0;
            }

            &.k-cancel, &.k-i-cancel {
                background-position: -56px 0;
            }

            &.k-expand, &.k-i-expand {
                background-position: -112px 0;
            }

            &.k-contract, &.k-i-contract {
                background-position: -140px 0;
            }

            &.k-up, &.k-i-up {
                background-position: -168px 0;
            }

            &.k-down, &.k-i-down {
                background-position: -196px 0;
            }

            &.k-magnify, &.k-i-magnify {
                background-position: -224px 0;
            }

            &.k-undo, &.k-i-undo {
                background-position: -252px 0;
            }

            &.k-refresh, &.k-i-refresh {
                background-position: -280px 0;
            }

            &.k-copy, &.k-i-copy {
                background-position: -308px 0;
            }

            &.k-send, &.k-i-send {
                background-position: -336px 0;
            }

            &.k-send-command, &.k-i-send-command {
                background-position: -364px 0;
            }

            &.k-settings, &.k-i-settings {
                background-position: -392px 0;
            }

            &.k-edit, &.k-i-edit {
                background-position: -420px 0;
            }

            &::before {
                content: none;
            }
        }

        &.k-grid-save-changes {
            .k-icon {
                margin-right: 15px;
                background-position: 0;
            }

            padding-right: 20px;
        }

        &.disabled {
            cursor: default;
            opacity: 0.6;
            -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#e9edf0', endColorstr='#ced1d6')" !important;
            filter: alpha(opacity=60), progid:DXImageTransform.Microsoft.gradient(startColorStr='#e9edf0', endColorStr='#ced1d6') !important;
            background: -webkit-gradient(linear, left top, left bottom, from(#e9edf0), to(#ced1d6)) !important;
            background: -moz-linear-gradient(top, #e9edf0, #ced1d6) !important;
            background: linear-gradient(to bottom,#e9edf0 0,#ced1d6 100%) !important;
            -moz-transition: none;
            -o-transition: none;
            -webkit-transition: none;
            transition: none;

            &:hover {
                color: #000;
                background-color: #CED1D6;
                border-color: #BEBFC3;
            }

            &:focus {
                box-shadow: none;
                color: #000;
                background-color: #CED1D6;
                border-color: #BEBFC3;
            }

            &:active {
                color: #000;
                background-color: #CED1D6;
                border-color: #BEBFC3;
            }

            .k-icon {
                background-image: url('../../images/grid-button-sprites-grey.png');
            }
        }

        &:hover {
            color: #fff;
            background: #77A820;
        }

        &:active {
            border-color: #BEBFC3;
        }

        &.clicked {
            background: #2d6eca;
            color: #fff;
        }
    }

    .record-num {
        color: #fcfcfa !important;
        //background-image: url('textures/highlight.png');
        background-image: none, -webkit-linear-gradient(top, #8eafe4 8%, #6e94cc 58%, #3e6eb0 62%);
        background-image: none, -moz-linear-gradient(top, #8eafe4 8%, #6e94cc 58%, #3e6eb0 62%);
        background-image: none, -o-linear-gradient(top, #8eafe4 8%, #6e94cc 58%, #3e6eb0 62%);
        background-image: none, linear-gradient(to bottom, #8eafe4 8%, #6e94cc 58%, #3e6eb0 62%);
        background-position: 50% 50%;
        background-color: #7399d0;
        padding: 5px 10px 0 10px;
        float: right;
        height: 26px;
        margin-top: 3px;
    }
}

@keyframes inlineSuccessFadeInOut {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    80% {
        opacity: 1
    }

    100% {
        opacity: 0;
    }
}

.audio-cell {
    display: flex;
    justify-content: center;

    .audio-text-area {
        flex-grow: 1;
    }

    .audio-button-area {
        width: 25px;
        text-align: center;

        .play-audio {
            display: block;
            width: 18px;
            height: 16px;
            background: url('../../images/audio.png') no-repeat top left;
            cursor: pointer;
            border: 0;

            .play-audio:hover {
                background-position: bottom left;
            }

            .play-audio.loading {
                background-image: url('../../images/loader.gif');
            }

            &.loading {
                pointer-events: none;
            }
        }
    }
}
