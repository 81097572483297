.emergency {
    padding: .58em;
    background: #d1d1d1;
    height: 96%;

    .emergency-map {
        height: 350px;

        .aerial-toggle {
            right: auto;
            left: 20px;
            top: 10px;
        }

        .leaflet-control-zoom {
            margin-top: 75px;
        }

        .leaflet-div-icon {
            background: transparent;
            border: none;
        }
    }

    .emergency-buttons {
        margin-top: 12px;
        display: flex;
        justify-content: space-around;

        button.green {
            font-size: 16px;
        }
    }

    .emergency-info {
        top: 12px;
        right: 13px;
        position: absolute;
        border: 2px solid #b0d0f6;
        background-color: #fff;
        border-radius: 4px;
        padding: 5px;
        width: 170px;
        z-index: 100;
        line-height: 15px;

        .location-message {
            display: flex;
            align-items: center;
            justify-content: space-between;

            img {
                width: 18px;
                height: 28px;
            }

            p {
                width: 140px;
            }
        }
    }
}
