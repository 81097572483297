#messageInbox {
    background-color: #F7F8FA;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0;

    .smi-grid {
        height: 100%;
    }

    #wrapper {
        background-color: #F7F8FA;
        min-height: 0;
        padding: 20px; //No padding on bottom to allow some flexibility in height-adjustment
    }

    .k-detail-cell {
        .grid-toolbar {
            display: none;
        }
    }

    .no-replies {
        .k-hierarchy-cell {
            .k-icon {
                display: none;
            }
        }
    }

    .k-grid-reply {
        height: 25px;
        width: 25px;
        border: none;
        box-shadow: none;
        overflow: hidden;
        display: block;
        margin: 0 auto;
        background-position: top left;
        background-repeat: no-repeat;
        background-image: url('../../images/forward-sprites.png');
    }

    .is-unread td {
        font-weight: bold;
    }

    .k-toolbar, .grid-toolbar {

        #messageStatusSelector, #searchArea, #newMessageArea {
            float: left;
        }

        #newMessageArea {
            .k-grid-send-message {
                filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#c6dec1', endColorStr='#a5cc98');
                -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#c6dec1', endColorstr='#a5cc98')";
                background: -webkit-gradient(linear, left top, left bottom, from(#c6dec1), to(#a5cc98));
                background: -moz-linear-gradient(top, #c6dec1, #a5cc98);
                background: linear-gradient(to bottom,#c6dec1 0,#a5cc98 100%);
            }
        }

        #messageStatusSelector {
            padding: 5px 20px 5px 0;
            border: 1px inset #e0e4e7;
            border-radius: 12px;
            margin-right: 30px;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#c9cdd0', endColorStr='#fff');
            -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#c9cdd0', endColorstr='#fff')";
            background: -webkit-gradient(linear, left top, left bottom, from(#c9cdd0), to(#fff));
            background: -moz-linear-gradient(top, #c9cdd0, #fff);
            background: linear-gradient(to bottom,#c9cdd0 0,#fff 100%);

            label {
                margin-left: 20px;

                &:first-child {
                    margin-left: 15px;
                }
            }
        }

        #searchArea {
            margin-right: 24px;

            #searchButton {
                display: block;
                width: 66px;
                height: 30px;
                background: url('../../images/search-button.png') no-repeat top left;
                float: left;

                &:hover {
                    background-position: 0 -31px;
                }

                &:active {
                    background-position: 0 -62px;
                }
            }

            #searchQuery {
                float: left;
                display: block;
                padding: 5px;
                border: 2px solid #9da2a6;
                border-radius: 2px;
                margin-right: 9px;
            }
        }
    }
}
