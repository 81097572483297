.main-page {
    display: flex;
    flex-direction: column;
    height: 100vh;


    .portal-header {
        display: flex;
        height: 60px;
        position: relative;
        justify-content: center;
        z-index: 100;
        background-image: url(../../images/top_bgr.gif);
        background-repeat: repeat-x;

        .homepage-menu {
            transform: translateX(-507px);
            max-height: 600px;
            overflow-y: auto;
            scrollbar-width: none;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                width: 0 !important;
            }
        }

        .sysbtns {
            position: absolute;
            right: 30px;
            top: 1px;
            margin: 0;
            padding: 0;
            list-style: none;
            z-index: 2;
        }

        .sysbtn {
            display: inline-block;
            z-index: 15;
            width: 80px;
            height: 40px;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
            margin: 0;
            padding: 0;

            &.messages {
                background-image: url('../../images/menu_0000_messages_1.png');

                &:hover {
                    background-image: url('../../images/menu_0001_messages_2.png');
                }
            }

            &.alerts {
                background-image: url('../../images/menu_0002_alerts_1.png');

                &:hover {
                    background-image: url('../../images/menu_0003_alerts_2.png');
                }
            }

            &.settings {
                background-image: url('../../images/menu_0004_settings_1.png');

                &:hover {
                    background-image: url('../../images/menu_0005_settings_2.png');

                    > .submenu {
                        transform: translateX(-100px);
                    }
                }
            }

            &.logout {
                background-image: url('../../images/menu_0006_logout_1.png');

                &:hover {
                    background-image: url('../../images/menu_0007_logout_2.png');
                }
            }

            &.outbox {
                background-image: url('../../images/menu_0008_outbox_1.png');

                &:hover {
                    background-image: url('../../images/menu_0009_outbox_2.png');
                }
            }

            &:hover {
                > .submenu {
                    display: block;
                    top: 40px;
                    z-index: 2;

                    li {
                        padding-left: 25px;
                        box-sizing: border-box;
                    }
                }
            }
        }

        .expander {
            width: 21px;
            height: 22px;
            line-height: 14px;
            overflow: hidden;
            position: absolute;
            bottom: 0;
            right: 0;
            display: block;
            cursor: pointer;
            background: linear-gradient(to bottom, #8c0501 0%, #c40100 100%);
            color: #fff;
            font-weight: bold;
            border: 0;
            padding: 0;
            margin: 0;
            border-radius: 8px 0 0 0;
            font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
            font-size: 22px;

            &::after {
                content: "↑";
            }

            &:hover {
                background: linear-gradient(to bottom, #c40100 0%, #8c0501 100%);
            }
        }

        &.collapse {
            height: 22px;

            .expander {
                border-radius: 0 0 0 8px;

                &::after {
                    content: "↓";
                }
            }

            > :not(.expander) {
                display: none !important;
            }
        }

        #logo {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;

            span {
                color: #b70c11;
                font-size: 12px;
                margin-left: 14px;
            }
        }

        .nav-menu {
            list-style: none;
            margin: 0;
            padding: 0;
            z-index: 100;

            @media only screen and (max-width: 1480px) {
                ul#nav {
                    left: 15%;
                    transform: none;
                }

                #logo {
                    width: 134px;
                    height: 54px;
                    overflow: hidden;
                }
            }


            > li {
                font-size: 13px;
                text-shadow: 0 2px 2px rgba(0, 0, 0, 0.85);
                font-family: Tahoma, Arial, sans-serif;
                text-transform: uppercase;
                width: 128px;
                height: 44px;
                display: inline-block;
                vertical-align: middle;
                line-height: 44px;
                color: #fff;
                margin: 0;
                padding-left: 60px;
                font-weight: 600;
                background: linear-gradient(to bottom, #540700 0%, #8c0501 50%, #c40100 100%);
                border-right: 1px solid #cf040a;
                border-left: 1px solid #7f0000;
                cursor: default;
                background-repeat: no-repeat;
                box-sizing: border-box;

                &:hover {
                    > .submenu {
                        display: block;
                        transform: translateX(-60px);
                    }
                }

                &:first-child {
                    border-radius: 0 0 0 10px;
                }

                &:last-child {
                    border-radius: 0 0 10px 0;
                }

                &.view {
                    background: url('../../images/view.png') no-repeat 10px 2px, linear-gradient(to bottom, #540700 0%, #8c0501 50%, #c40100 100%);

                    &:hover {
                        background: url('../../images/view.png') no-repeat 10px 2px, linear-gradient(to bottom, #540700 0%, #a21317 50%, #e82d39 100%);
                    }
                }

                &.maintain {
                    background: url('../../images/maintain.png') no-repeat 10px 2px, linear-gradient(to bottom, #540700 0%, #8c0501 50%, #c40100 100%);
                    background-position-x: 3px;
                    padding-left: 53px;

                    &:hover {
                        background: url('../../images/maintain.png') no-repeat 10px 2px, linear-gradient(to bottom, #540700 0%, #a21317 50%, #e82d39 100%);
                        background-position-x: 3px;
                    }
                }

                &.system {
                    background: url('../../images/system.png') no-repeat 10px 2px, linear-gradient(to bottom, #540700 0%, #8c0501 50%, #c40100 100%);

                    &:hover {
                        background: url('../../images/system.png') no-repeat 10px 2px, linear-gradient(to bottom, #540700 0%, #a21317 50%, #e82d39 100%);
                    }
                }

                &.reports {
                    background: url('../../images/reports.png') no-repeat 10px 2px, linear-gradient(to bottom, #540700 0%, #8c0501 50%, #c40100 100%);

                    &:hover {
                        background: url('../../images/reports.png') no-repeat 10px 2px, linear-gradient(to bottom, #540700 0%, #a21317 50%, #e82d39 100%);
                    }
                }

                &.community {
                    background: url('../../images/community.png') no-repeat 10px 2px, linear-gradient(to bottom, #540700 0%, #8c0501 50%, #c40100 100%);
                    width: 150px;

                    &:hover {
                        background: url('../../images/community.png') no-repeat 10px 2px, linear-gradient(to bottom, #540700 0%, #a21317 50%, #e82d39 100%);
                    }
                }
            }
        }

        #greeting {
            font-family: Tahoma, Arial, sans-serif;
            font-size: 11px;
            position: absolute;
            bottom: 7px;
            right: 52px;
            z-index: 1;
        }
    }

    .page-content {
        flex: 1;
    }

    .withalert {
        float: left;
        margin-top: 3px;
        margin-left: 2px;
        color: white;
        font-size: 11px;
        border-radius: 3px;
        padding: 1px 2px;
        background: red;
    }
}

#editScheduleDialog {
    z-index: 9999;
    position: absolute;
}
.send-message, .alert-outbox {
    .k-window-content {
        padding: 0px !important;
    }    
}